import { defineComponent, reactive } from 'vue';
import { ElSelect, ElOption, ElProgress } from 'element-plus';
import { toRefs } from '@vueuse/core';
const playSpeedOptions = [{
  value: 800,
  label: '慢'
}, {
  value: 400,
  label: '中'
}, {
  value: 200,
  label: '快'
}];
export default defineComponent({
  name: 'PlayProgress',
  components: {
    ElSelect,
    ElOption,
    ElProgress
  },
  emits: ['update:playSpeed', 'playClick'],
  props: {
    playSpeed: {
      type: Number,
      default: 800
    },
    playProgress: {
      type: Number,
      default: 0
    },
    playStatus: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const refData = reactive({});
    return {
      ...toRefs(refData),
      playSpeedOptions
    };
  }
});