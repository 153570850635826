import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_el_progress = _resolveComponent("el-progress");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "cursor-pointer",
    onClickPassive: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.$emit('playClick'), ["stop"]))
  }, [[0, 1, 3].includes(_ctx.playStatus) ? (_openBlock(), _createBlock(_component_Icon, {
    key: 0,
    style: {
      "font-size": "32px",
      "color": "blue"
    },
    name: "icon-bofang"
  })) : _createCommentVNode("", true), [2].includes(_ctx.playStatus) ? (_openBlock(), _createBlock(_component_Icon, {
    key: 1,
    style: {
      "font-size": "32px",
      "color": "blue"
    },
    name: "icon-zanting"
  })) : _createCommentVNode("", true)], 32), _createVNode(_component_el_progress, {
    percentage: _ctx.playProgress,
    "stroke-width": 20,
    "text-inside": true
  }, null, 8, ["percentage"]), _createVNode(_component_el_select, {
    modelValue: _ctx.playSpeed,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.$emit('update:playSpeed', $event))
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playSpeedOptions, opt => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: opt.value,
        value: opt.value,
        label: '速度: ' + opt.label
      }, null, 8, ["value", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"])]);
}